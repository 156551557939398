// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

// import LanguageDropdown from './LanguageDropdown';
import NotificationDropdown from './NotificationDropdown';
import ProfileDropdown from './ProfileDropdown';
import SearchDropdown from './SearchDropdown';
import TopbarSearch from './TopbarSearch';
import ListaModulos from './ListaModulos';
// import AppsDropdown from './AppsDropdown';
import { showRightSidebar } from '../redux/actions';

import profilePic from '../assets/images/users/account_circle_grey_192x192.png';
import logoSm from '../assets/images/logo_sm.png';
import logo from '../assets/images/logo-light.png';
// import logoRelevo from '../assets/images/logo-mono-beta.svg';
import logoRelevo from '../assets/images/logo_ERP_Ligth.png';
import iconSD_AZUL from '../assets/images/SD-Azul.png'
import iconSD from '../assets/images/SD.svg';
// import { Button } from 'reactstrap';
import { gatewayUrl } from '../constants/globalConfig';

import { GetNotifications, UpdateVisualizedDateByNotificationType } from '../models/dashboard';
import AuthService from '../helpers/authService';

import { AuthConsumer } from '../context/authContext';
import { Input } from 'reactstrap';
import { modulesSystem } from '../context/Meta';
import { Redirect } from '../helpers/Utils';

import ModalTalkToEspecialist from './ModalTalkToEspecialist';
import ModalSupport from './ModalSupport';
import RelevoPopover from "../components/Relevo/RelevoPopover/RelevoPopover";
import io from 'socket.io-client';

const ProfileMenus = [
    {
        label: 'Meu Perfil',
        icon: 'uil uil-user',
        newTab: false,
        redirectTo: '/account/profile',
    },
    {
        label: 'Termo de Uso',
        icon: 'mdi mdi-file-document-outline',
        newTab: true,
        redirectTo: '/account/term-accept',
    },
    {
        label: 'Suporte',
        icon: 'mdi mdi-help-circle-outline',
        newTab: true,
        redirectTo: '/account/support',
    },
    // {
    //     label: 'Settings',
    //     icon: 'uil uil-cog',
    //     redirectTo: '/',
    // },
    // {
    //     label: 'Support',
    //     icon: 'uil uil-life-ring',
    //     redirectTo: '/',
    // },
    // {
    //     label: 'Lock Screen',
    //     icon: 'uil uil-lock-alt',
    //     redirectTo: '/',
    // },
    {
        label: 'Sair',
        icon: 'uil uil-exit',
        newTab: false,
        redirectTo: '/account/logout',
    },
];

type TopbarProps = {
    showRightSidebar: PropTypes.func,
    hideLogo?: boolean,
    hideSearch?: boolean,
    hideNotifications?: boolean,
    hideProfile?: boolean,
    hideModule?: Boolean,
    hideSimplificTips?: boolean,
    hideTalkToSpecialist?: Boolean,
    hidePendo?: Boolean,
    navCssClasses?: string,
    module?: string,
    openLeftMenuCallBack?: PropTypes.func,
};

// his.props.hideSimplificTips || false;
//         const hideTalkToSpecialist = this.props.hideTalkToSpecialist || false;


// const socket = io(
//     gatewayUrl,
//     {
//         path: "/notify-erp/socket",
//         autoConnect: false
//     });


class Topbar extends Component<TopbarProps> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            notifications: [],
            notificationsTips: [],
        }
        this.handleRightSideBar = this.handleRightSideBar.bind(this);
        // socket.connect();

    }

    /**
     * Toggles the right sidebar
     */
    handleRightSideBar = () => {
        this.props.showRightSidebar();
        //this.updateVisualizedDate(false, true);
    };

    // initSocket = (authService) => {
    //     socket.on('send_notification', (e) => {
    //         if (authService.getCompanyId() === e) {
    //             this.loadNotifications(true, true);
    //         }
    //     })
    // }

    async componentDidMount() {
        const authService = new AuthService();
        // this.initSocket(authService);
        const token = authService.getToken();
        if (token) {
            this.loadNotifications(true, true);
        }
    }

    loadNotifications = async (updateNotifications, updateTips) => {
        const authService = new AuthService();

        const notifications = await GetNotifications(authService.getCompanyId())
        const notificationsMaped = notifications.map((i) => {
            return {
                Url: "/home/dashboard",
                ...i.message
            }
        });

        this.setState({
            notifications: updateNotifications ? [...notificationsMaped] : this.state.notifications,
            notificationsTips: updateTips ? [...notificationsMaped] : this.state.notificationsTips,
            loaded: true
        })
    }

    updateVisualizedDate = async (updateNotifications, updateTips) => {
        const authService = new AuthService();
        const updated = await UpdateVisualizedDateByNotificationType(authService.getCompanyId(), 'notificacao');
        this.loadNotifications(updateNotifications, updateTips);
    }


    hasNotVisualizedNotifications = () => {
        if (!this.state.notifications) {
            return false;
        }
        return this.state.notifications.some(x => !x.VisualizedDate);
    }

    hasNotVisualizedTips = () => {
        if (!this.state.notificationsTips) {
            return false;
        }
        return this.state.notificationsTips.some(x => !x.VisualizedDate);
    }
    render() {
        const hideLogo = this.props.hideLogo || false;
        const navCssClasses = this.props.navCssClasses || '';
        const containerCssClasses = !hideLogo ? 'container-fluid' : '';
        const hideSearch = this.props.hideSearch || false;
        const hideNotifications = this.props.hideNotifications || false;
        const hideProfile = this.props.hideProfile || false;
        const hideModule = this.props.hideModule || false;
        const hideSimplificTips = this.props.hideSimplificTips || false;
        const hideTalkToSpecialist = this.props.hideTalkToSpecialist || false;
        const hidePendo = this.props.hidePendo || false;

        const module = this.props.module || '';
        const { loaded, notifications } = this.state;




        return (
            <React.Fragment>
                <div className={`navbar-custom ${navCssClasses}`}>
                    <div className={containerCssClasses}>
                        {!hideLogo && (
                            <Link to="/" className="topnav-logo">
                                <span className="topnav-logo-lg">
                                    <img src={logoRelevo} alt="logo" height="30" style={{ margin: 20 }} />
                                </span>
                            </Link>
                        )}

                        {module !== '' && (
                            <ul className="container-separated-modules list-unstyled topbar-right-menu mb-0">
                                {modulesSystem.map((item, index) => {
                                    return (
                                        <>
                                            {!item.isCounter ?
                                                <li data-toggle="tooltip" data-placement="top" title={item.title}
                                                    className={`nav-item ${item.className} ${(module.module == item.module ? "active" : "")}`}
                                                    style={{ marginTop: '5px', marginLeft: '10px' }}>
                                                    <a className="nav-link" aria-current="page" href={item.module}>
                                                        <div className={`${item.textClassName} topnav-logo-text`}>
                                                            <ReactSVG
                                                                src={item.img}
                                                                beforeInjection={(svg) => {
                                                                    svg.classList.add(item.svgClassName);
                                                                }}
                                                            />
                                                            <span style={{ fontSize: '12px' }}>
                                                                {item.title} 
                                                            </span>
                                                        </div>
                                                    </a>
                                                </li> : null
                                            }
                                            {
                                                <AuthConsumer>
                                                    {({ getTokenDecode, getUserIsCounter }) => {
                                                        const token = getTokenDecode();
                                                        
                                                        return (
                                                            <>
                                                                {(item.isCounter && getUserIsCounter()) ?
                                                                    <li data-toggle="tooltip" data-placement="top" title={item.title}
                                                                        className={`nav-item ${item.className} ${(module.module == item.module ? "active" : "")}`}
                                                                        style={{ marginTop: '5px', marginLeft: '10px' }}>
                                                                        <a className="nav-link" aria-current="page" href={item.module}>
                                                                            <div className={`${item.textClassName} topnav-logo-text`}>
                                                                                <ReactSVG
                                                                                    src={item.img}
                                                                                    beforeInjection={(svg) => {
                                                                                        svg.classList.add(item.svgClassName);
                                                                                    }}
                                                                                />
                                                                                <span style={{ fontSize: '12px' }}>
                                                                                    {item.title+' '} 
                                                                                    {/* <br /> */}
                                                                                    / {token.usuarioDB.empresas[0].razao_social}
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    : null}
                                                            </>
                                                        )
                                                    }}
                                                </AuthConsumer>
                                            }
                                        </>
                                    )
                                })}
                            </ul>
                        )}
                        {!hideModule &&
                            <AuthConsumer>
                                {({ getTokenDecode, getUserIsCounter }) => {
                                    return (
                                        <ListaModulos modules={modulesSystem} moduleActive={module} isCounter={getUserIsCounter()} />
                                    );
                                }}
                            </AuthConsumer>
                        }

                        <ul className="list-unstyled topbar-right-menu float-right mb-0">
                            {!hideSimplificTips &&
                                <AuthConsumer>
                                    {({ getTokenDecode }) => {
                                        const isFreePlan = getTokenDecode()?.teste_gratis ? true : false;

                                        return (
                                            <>
                                                {/* <li className="notification-list">
                                                    <div data-toggle="tooltip" data-placement="left" title="Simplific Dicas"
                                                        onClick={() => this.handleRightSideBar()}
                                                        // style={{ height: '100%', paddingTop: '23px', paddingBottom: '23px', width: '52px', textAlign: 'center' }}
                                                        className="container-icon-simplific-tips"
                                                    >
                                                        <img id={`simplific-tips-icon`} src={iconSD} />

                                                    </div>
                                                </li> */}
                                                <li className="notification-list" id="button-simplific-dicas">
                                                    <div data-toggle="tooltip" data-placement="left" title="Simplific Dicas"
                                                        onClick={() => this.handleRightSideBar()}
                                                        // style={{ height: '100%', paddingTop: '23px', paddingBottom: '23px', width: '52px', textAlign: 'center' }}
                                                        className="new-item-simplific-tips"
                                                    >
                                                        <div className="span-simplific-tips">
                                                            <i className="mdi mdi-arrow-left" /> Simplific Dicas
                                                        </div>

                                                        {/* <img id={`simplific-tips-icon`} src={iconSD} /> */}

                                                    </div>
                                                </li>


                                            </>)
                                    }}
                                </AuthConsumer>
                            }


                            {!hidePendo && <li className="notification-list">
                                <span class="blink_me noti-icon-badge">
                                </span>
                                <button class="nav-link dropdown-toggle arrow-none btn btn-link" onClick={() => { }}>


                                    <span data-toggle="tooltip" data-placement="left" title="Primeiros passos" id="pendo_first_steps">

                                        <i className="mdi mdi-information noti-icon"></i>

                                    </span>

                                </button>

                            </li>
    }


                            {!hideTalkToSpecialist &&
                                <ModalTalkToEspecialist />
                            }




                            <AuthConsumer>
                                {({ getTokenDecode, isAuthenticated }) => {

                                    const isFreePlan = isAuthenticated() && getTokenDecode()?.teste_gratis ? true : false;
                                    return (
                                        <>
                                            {
                                                isFreePlan &&
                                                <ModalSupport />
                                            }



                                        </>
                                    )

                                }}



                            </AuthConsumer>


                            {
                                !hideNotifications && (
                                    <li className="notification-list">
                                        {this.hasNotVisualizedNotifications() &&
                                            <span className="blink_me noti-icon-badge"></span>
                                        }
                                        <NotificationDropdown
                                            updateVisualizedDate={() => this.updateVisualizedDate(true, false)}
                                            notifications={notifications} load={loaded} />
                                    </li>
                                )
                            }


                            {
                                !hideProfile && (
                                    <li className="notification-list">
                                        <AuthConsumer>
                                            {({ getUser }) => {
                                                return (
                                                    <ProfileDropdown
                                                        profilePic={profilePic}
                                                        menuItems={ProfileMenus}
                                                        user={getUser()}
                                                    />
                                                );
                                            }}
                                        </AuthConsumer>
                                    </li>
                                )
                            }
                        </ul>

                        <button
                            className="button-menu-mobile open-left disable-btn"
                            onClick={this.props.openLeftMenuCallBack}>
                            <i className="mdi mdi-menu"></i>
                        </button>

                        {/* {!hideSearch && <TopbarSearch items={SearchResults} />} */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(null, { showRightSidebar })(Topbar);
