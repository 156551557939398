// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';

import { Provider } from 'mobx-react';
import store from '../src/stores/store';
import './assets/scss/Saas.scss';
import { AuthConsumer, AuthProvider } from './context/authContext';
import AuthService from './helpers/authService';
import LayoutProvider from './context/LayoutContext';
import './assets/theme-kendo/theme-kendo-2.0.css';
import esMessages from './kendo.messages.pt-BR.json';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';


import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/pt/numbers.json';
import currencies from 'cldr-numbers-full/main/pt/currencies.json';
import caGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pt/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/pt/timeZoneNames.json';

import RelevoPopover from "./components/Relevo/RelevoPopover/RelevoPopover";
import RelevoCommunicationCard from './components/Relevo/RelevoCommunicationCard';
import { updateVisualizedUrl } from './models/communication';


loadMessages(esMessages, 'pt-BR');
load(
    esMessages,
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    dateFields,
    caGregorian,
    timeZoneNames
);



// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

/**
 * Main app component
 */
class App extends Component {

    state = {
        popovers: [],
        communication: [],
        modalOpen: [],
        passLocation: null
    }
    // componentWillMount() {
    //     if (!window.location.href.includes('/account/login')) {
    //         window.location.href = '/account/login';
    //     }
    // }

    async closeModalAndVisualize(index, id) {
        let open = this.state.modalOpen;
        open[index] = false;
        this.setState({ modalOpen: open });
        await updateVisualizedUrl(id);
        this.forceUpdate();
    }

    render() {
        

        const { popovers, modalOpen } = this.state;
        let { communication } = this.state;
        if (
            document.getElementById('modal-include-company')
        ) {
            communication = communication.filter(x => x.PageUrl != '/home/dashboard');
        }

        try {

        const authService = new AuthService();
        const _userInfo = authService.getUser()
        const _userPlan = authService.getPlan()
        const _companyId = authService.getCompanyId()
        const _razaoSocial = authService.getRazaoSocial()
 

        window.pendo.initialize({

            visitor: {

                id: _userInfo.usuario_id,

                nomeUsuario: _userInfo.nome,

                email: _userInfo.email,

                criadoEm: _userInfo.criado_em,

                lastLogin: _userInfo.lastLogin

            },

            account: {

                id: _companyId,

                razaoSocial: _razaoSocial,

                plan: _userPlan.Plan.Name

            }

        })
            
        } catch (error) {
            
        }

        

        return (
            <AuthProvider>
                <LayoutProvider>
                    <LocalizationProvider language="pt-BR">
                        <IntlProvider locale="pt" >
                            <Provider {...store}>
                                <Routes appcontext={this}></Routes>
                            </Provider>
                        </IntlProvider>
                    </LocalizationProvider>
                    {popovers.map((popover) => (
                        <RelevoPopover idReference={popover.IdBotao} title={popover.Title} text={popover.Text} hideOnFreeSimulation={true} />
                    ))}
                    {/* {communication.map((modal, index) => {
                        return (
                            <RelevoCommunicationCard
                                type={modal.Text === 'carousel' ? 99 : modal.TypeId}
                                comunicationId={modal.IdComunication}
                                isOpen={modalOpen[index]}
                                toggle={() => this.closeModalAndVisualize(index, modal.IdComunication)}
                                content={modal.content}
                            />
                        )

                    })} */}
                </LayoutProvider>
            </AuthProvider>
        );
    }
}

export default App;
