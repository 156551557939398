import { formatNumberToParts } from '@formatjs/intl';
import React from 'react';
import calculateSize from "calculate-size";
import { RelevoBadge, TYPES_BADGE } from '../components/Relevo/RelevoBadge';
import { PlanoPessoaFisica, PlanoPessoaJuridica } from '../pages/auth/myProfile/Meta';

export const handleInputChange = (event = null, context = null) => {
    if (event === null || context === null) return;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    context.setState({
        [name]: value,
    });
};

export const handlerMaskCpfCnpj = (e, context) => {
    if (!e || !context) return;
    const target = e.target;
    const name = target.name;
    context.setState({ [name]: maskCpfCnpj(target.value) });
};

export const maskCpfCnpj = (documento) => {
    if (!documento) return '';
    let final = documento.toString().replace(/(\.|\/|\-)/g, '');
    if (final.length <= 11) final = final.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    else {
        final = final.substring(0, 14);
        final = final.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }
    return final;
};

export const maskCellphone = (value) => {
  // Converte o valor para string, caso não seja
  let valorString = value.toString();
  
  // Remove todos os caracteres que não são dígitos
  let apenasNumeros = valorString.replace(/\D/g, '');

  // Aplica a máscara, considerando números de 8 e 9 dígitos após o DDD
  // A parte `(\d{4,5})` permite que o segundo grupo tenha 4 ou 5 dígitos
  let valorMascarado = apenasNumeros.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');

  // Verifica se o número excede o tamanho da máscara e corta o excesso
  valorMascarado = valorMascarado.substring(0, 15);
  
  return valorMascarado;
}

export const maskCep = (value) => {
    if (!value) return;

    let v = value.toString().replace(/\D/g, '').substring(0, 8);
    v = v.replace(/(\d{5})(\d{3})$/, '$1-$2');
    return v;
};

export const handlerInputCepMask = (e, context) => {
    const { target } = e;
    const name = target.name;

    context.setState({
        [name]: maskCep(target.value),
    });
};

export const maskTelefone = (valor) => {
    if (!valor) return '';
    // let final = valor.toString().replace(/(\.|\/|\-)/g, '');
    let final = valor.toString().replace(/\D/g, '');

    final = final.replace(/(\d)(\d{4})$/, '$1-$2');
    final = final.replace(/^(\d{2})(\d)/g, '($1) $2');

    return final;
};

export const chooseStatusBadge2 = (e) => {
    return chooseStatusBadge(e, 1);
};

export const chooseStatusBadge = (e, billsType) => {
    const { dataItem, field } = e;
    const status = dataItem[field];

    return chooseStatusBadgeByValue(status, billsType);
};

export const chooseStatusBadgeByValue = (value, billsType) => {
    let badge = {};
    switch (value) {
        case 'PAGO':
            badge = {
                text: billsType == 1 ? 'Pago' : 'Recebido',
                type: TYPES_BADGE.SUCCESS
            }
            break;
        case 'A VENCER':
            badge = {
                text: 'A vencer',
                type: TYPES_BADGE.SECONDARY
            }
            break;
        case 'VENCE HOJE':
            badge = {
                text: 'Vence Hoje',
                type: TYPES_BADGE.WARNING
            }
            break;
        case 'ATRASADO':
            badge = {
                text: 'Atrasado',
                type: TYPES_BADGE.DANGER
            }
            break;
        case 'Completo':
            badge = {
                text: 'Completo',
                type: TYPES_BADGE.SUCCESS
            }
            break;
        case 'Parcial':
            badge = {
                text: 'Parcial',
                type: TYPES_BADGE.WARNING
            }
            break;
        case 'Erro':
            // return (
            //     <td>
            //         <span className={'badge badge-danger-lighten'}>Erro</span>
            //     </td>
            // );
            badge = {
                text: 'Erro',
                type: TYPES_BADGE.DANGER
            }
            break;
        default:
            return (
                <td>
                    <span></span>
                </td>
            );
    }

    return <td>
        <RelevoBadge text={badge.text}
            type={badge.type} />
    </td>
}

export const tryParseInt = (num) => {
    try {
        return parseInt(num);
    }
    catch (e) {
        return 0;
    }
}

export const renderFieldBadgeApportionments = (e, fieldName) => {

    const { dataItem, field } = e;

    const apportionmenstList = dataItem[fieldName + "Apportionments"];

    return (
        <td>
            {apportionmenstList && apportionmenstList.map(element => {
                const distributedAmount = element["distributedAmount"];
                const apportionmentName = element[fieldName + "Name"];
                const distributedPercent = element["distributedPercent"]
                return <>
                    <RelevoBadge text={apportionmentName + " (" + distributedPercent + "%) "}
                        type={TYPES_BADGE.SECONDARY} />
                    &nbsp;
                </>

            })
            }
        </td >
    );
}

export const validateContact = (contact) => {
    if (contact.Name != null && contact.TradeMark != null) return true;

    return false;
};

export const cleanMask = (mask, isMoney = false) => {
    if (!mask) return '';
    if (isMoney) {
        return parseFloat(String(mask).replaceAll('.', '').replace(',', '.').replace('R$', ''));
    }

    return mask
        .toString()
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '')
        .replace('(', '')
        .replace(')', '');
};

export const handlerBollean = (state, setStateFunction) => {
    setStateFunction(!state);
    return !state;
};

export const FormaterReal = (br) => {
    if (!br || br === 0 || br === null || br === '0') return 'R$ 0,00';

    try {
        const final = br.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return final.indexOf('NaN') > -1 ? 'R$ 0,00' : final;
    } catch {
        return 'R$ 0,00';
    }
};

export const GetAllNumber = (br) => {
    if (!br) return '';
    let v = br.toString().replace(/\D/g, '').substring(0, 8);
    return v;
};

export const Redirect = (url) => {
    window.location = url;
};

export const FormatarDataStringOrObject = (dateStr) => {
    if (typeof dateStr == 'string') {
        return dateStr;
    } else {
        return FormatarData(dateStr);
    }
}
export const FormatarData = (dateStr) => {
    if (!dateStr) return '';
    try {
        let data = new Date(dateStr),
            dia = data.getDate().toString(),
            diaF = dia.length === 1 ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(),
            mesF = mes.length == 1 ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + '/' + mesF + '/' + anoF;
    } catch {
        return dateStr;
    }
};

///Para Python
export const FormatarDataV2 = (dateStr) => {
    try {
        const data = dateStr.split('-');
        return `${data[2]}/${data[1]}/${data[0]}`;
    } catch {
        return dateStr;
    }
};

export const DataCSharpToJs = (data) => {
    if (!data || data === null) return;
    return data.split('T')[0];
};

export const detectCpfOrCnpj = (data = null) => {
    if (data === null) return false;
    if (/(\d{3}).(\d{3}).(\d{3})-(\d{2})/g.exec(data) !== null) return true;
    else if (/(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})/g.exec(data) !== null) {
        return true;
    }
    return false;
};

export const isMobile = () => {
    return navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
        ? true
        : false;
};

export const Spliter = (data, delimiter, position = 0) => {
    if (!data || !delimiter) return '';
    const final = data.split(delimiter);
    return final[position];
};

export const GetRangeYears = (startYear, desc = false) => {
    const currentYear = new Date().getFullYear();
    let years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    if (desc) {
        years.sort(function (a, b) {
            return b - a;
        });
    }
    return years;
};

export const GetRangeMonth = () => {
    const month = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];
    return month;
};

export const mesAtualInterval = (actualMonth, anos) => {
    const first = new Date(anos, actualMonth);
    const last = new Date(anos, actualMonth, 0);

    return {
        first: `${first.getFullYear()}-${('0' + first.getMonth()).slice(-2)}-${('0' + first.getDate()).slice(-2)}`,
        last: `${last.getFullYear()}-${('0' + (last.getMonth() + 1)).slice(-2)}-${('0' + last.getDate()).slice(-2)}`,
    };
};

export const monthInterval = (actualMonth, anos) => {
    const first = new Date(anos, actualMonth);
    const last = new Date(anos, actualMonth, 0);

    return {
        first: `${last.getFullYear()}-${('0' + (last.getMonth() + 1)).slice(-2)}-${('0' + first.getDate()).slice(-2)}`,
        last: `${last.getFullYear()}-${('0' + (last.getMonth() + 1)).slice(-2)}-${('0' + last.getDate()).slice(-2)}`,
    };
};

export const mesPeriodo = (monthInit, yearInit, monthEnd, yearEnd) => {
    const first = new Date(yearInit, monthInit - 1, 1);
    const last = new Date(yearEnd, monthEnd, 0);

    return {
        first: `${first.getFullYear()}-${('0' + (first.getMonth() + 1)).slice(-2)}-${('0' + first.getDate()).slice(
            -2
        )}`,
        last: `${last.getFullYear()}-${('0' + (last.getMonth() + 1)).slice(-2)}-${('0' + last.getDate()).slice(-2)}`,
    };
};

export const GetCurrentDate = (date) => {
    var d = date ? new Date(date) : new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const GetCurrentDateObject = (date) => {
    var d = date ? new Date(date) : new Date(),
        month = GetRangeMonth()[d.getMonth()],
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (day.length < 2) day = '0' + day;

    return { year, month, day };
};


export const GetDueDateCreditCard = (final) => {
    final = final.replace(/\D/g, '').substring(0, 6)
    return final.replace(/(\d{2})(\d{4})/g, '$1/$2');
}

export const GetCVVCreditCard = (final) => {
    final = final.replace(/\D/g, '').substring(0, 4)
    return final.replace(/(\d{4})/g, '$1');
}

export const GetCardNumber = (final) => {
    final = final.replace(/\D/g, '').substring(0, 16)
    return final.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$1 $2 $3 $4');
}


export const GetNumbers = (final) => {
    try {
        return `${final}`.replace(/\D/g, '');
    }
    catch {
        return ""
    }
}


export const DetectFlagCard = (number) => {
    var re = {
        electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        dankort: /^(5019)\d+$/,
        interpayment: /^(636)\d+$/,
        unionpay: /^(62|88)\d+$/,
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    }

    for (var key in re) {
        if (re[key].test(number)) {
            return key
        }
    }
}

export const convertDateToUTC = (date = new Date()) => {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}

export const getUTCNow = (date = new Date()) => {
    const tzoffset = (date).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const calculateWidth = (data, field) => {
    let maxWidth = 250;

    let calc = data?.map((item) => {

        let size = 0;

        if (field == "projectName") {
            const projectApportionments = item["projectApportionments"];
            const amount = item["amount"];

            let columnString = "";
            if (!projectApportionments) {
                size = calculateSize(item[field], {
                    font: "Nunito, sans-serif",
                    fontSize: "18px",
                });
            } else {
                projectApportionments.map(element => {
                    let distributedAmount = element["distributedAmount"];
                    let projectName = element["projectName"];
                    let distributedPercent = parseFloat((distributedAmount * 100) / amount).toFixed(2);

                    return columnString = columnString + projectName + " (" + distributedPercent + "%) ";
                })

                size = calculateSize(columnString, {
                    font: "Nunito, sans-serif",
                    fontSize: "17px",
                });
            }

        } else if (field == "categoryTypeName") {
            const categoryApportionments = item["categoryApportionments"];
            const amount = item["amount"];

            let columnString = "";

            if (!categoryApportionments) {
                size = calculateSize(item[field], {
                    font: "Nunito, sans-serif",
                    fontSize: "18px",
                });
            } else {
                categoryApportionments.map(element => {
                    let distributedAmount = element["distributedAmount"];
                    let categoryName = element["categoryName"];
                    let distributedPercent = parseFloat((distributedAmount * 100) / amount).toFixed(2);

                    return columnString = columnString + categoryName + " (" + distributedPercent + "%) ";
                })

                size = calculateSize(columnString, {
                    font: "Nunito, sans-serif",
                    fontSize: "17px",
                });
            }
        } else {
            size = calculateSize(item[field], {
                font: "Nunito, sans-serif",
                fontSize: "18px",
            });
        }

        if (size.width > maxWidth) {
            maxWidth = size.width;
        }
    });

    return maxWidth;
};



export const verifyProdOrPreprodEnviroment = () => {
    return (window.location.href.includes('app.simplificgestao.com') || window.location.href.includes('preprod.simplificgestao.com'))
}


export const validaCpfCnpj = (val) => {
    if (val.length == 14) {
        var cpf = val.trim();

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }

        if (v2 != cpf[10]) {
            return false;
        } else {
            return true;
        }
    } else if (val.length == 18) {
        var cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cnpj.length > i; i++) {
            if (cnpj[i - 1] != cnpj[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v1 += cnpj[i] * p1;
            } else {
                v1 += cnpj[i] * p2;
            }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
            v1 = 0;
        } else {
            v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
            return false;
        }

        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v2 += cnpj[i] * p1;
            } else {
                v2 += cnpj[i] * p2;
            }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
            v2 = 0;
        } else {
            v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export const formatCurrency = (value) => {
    try {
        if (value) {
            return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        }
    } catch { }

    return Number(0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function getOtherPlanInfo(actualPlan) {
    let hrefPlan;
    let textPlan;

    if (actualPlan?.name === "Plano Pessoa Física") {
        hrefPlan = `/payment/sign/["${PlanoPessoaJuridica?.ids[0]}","${PlanoPessoaJuridica?.ids[1]}"]`;
        textPlan = "Sou pessoa jurídica";
    } else {
        hrefPlan = `/payment/sign/["${PlanoPessoaFisica?.ids[0]}","${PlanoPessoaFisica?.ids[1]}"]`;
        textPlan = "Sou pessoa física";
    }

    return { href: hrefPlan, text: textPlan };
}
